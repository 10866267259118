import { defineStore } from 'pinia'

export const store = defineStore('store', {
  state: () => (
    {
        isScroll: true,
        windowWidth: window.innerWidth,
        langs: ['GE', 'ENG'],
        currentLang: localStorage.getItem('lang') ? localStorage.getItem('lang') : '',
        activeMouse: false,
        isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
        scrollY: 0,
        loaderTime: 3000,
        navLoaderTime: 500,
        isNavigate: false,
        domain: 'https://jortsoft.com',
        videoPopupOpen: "",
    }
  ),
  getters: {
    getIsScroll: (state) => state.isScroll,
    getWindowWidth: (state) => state.windowWidth,
    getLangCodes: (state) => state.langs,
    getCurrentLang: (state) => state.currentLang,
    getActiveMouse: (state) => state.activeMouse,
    getSafariNavigator: (state) => state.isSafari,
    getScrollY: (state) => state.scrollY,
    getLoaderTime: (state) => state.loaderTime,
    getNavLoaderTime: (state) => state.navLoaderTime,
    getIsNavigate: (state) => state.isNavigate,
    getDomain: (state) => state.domain,
    getVideoPopupOpen: (state) => state.videoPopupOpen
  },
  actions: {
    changeIsScroll(move: boolean): void {
      this.isScroll = move;
    },
    setActiveMouse(move: boolean): void {
      this.activeMouse = move;
    },
    changeCurrentLang(lang: string): void {
      this.currentLang = lang;
      localStorage.setItem('lang', this.currentLang.toUpperCase());
    },
    setScrollY(val: number): void {
      this.scrollY = val;
    },
    setIsNavigate(val: boolean): void {
      this.isNavigate = val;
    },
    openVideoPopup(val: string): void {
      this.videoPopupOpen = val;
    }
  }
})

export type storeType = ReturnType<typeof store>;
