<script setup lang="ts">
import { onMounted } from 'vue';
import Header from './components/Header.vue';
import VideoPopup from './components/VideoPopup.vue';
import { store } from '@/store/store'
import CustomCursor from './components/CustomCursor.vue';
import Loader from './components/Loader.vue';
import { useRoute } from 'vue-router';
import axios from 'axios';

const Store = store();
const route = useRoute();

const setDefaultLang = (): void => {
  if (localStorage.getItem('lang')) return;
  let currentLang;
  axios.get('https://api.country.is/')
    .then(response => {
      // Handle successful response
      if (response.data.country === 'GE') {
        currentLang = Store.getLangCodes[0];
      } else {
        currentLang = Store.getLangCodes[1];
      }
      Store.changeCurrentLang(currentLang);
    })
    .catch(error => {
      // Handle error
      console.error('Error fetching data: ' + error.message);
      currentLang = Store.getLangCodes[0];
      Store.changeCurrentLang(currentLang);
    });
}

window.addEventListener('scroll', () => {
  const isScroll: boolean = window.scrollY <= 40;
  Store.changeIsScroll(isScroll)
  Store.setScrollY(window.scrollY);
})

onMounted(() => {
  setDefaultLang();
})
</script>

<template>
  <Loader />
  <Header />
  <CustomCursor />
  <router-view />
  <VideoPopup />
</template>