<script setup lang="ts">
import { store } from '@/store/store';
import { ref, onMounted, Ref } from 'vue';
import { gsap } from 'gsap';

const Store = store();

const posX:Ref<number> = ref(0);
const posY:Ref<number> = ref(0);
const mouseWidth:Ref<number> = ref(120);

let mouseElem: HTMLElement | null;

onMounted(() => {
  mouseElem = document.querySelector('.custom-mouse');

  document.addEventListener('mousemove', (e) => {
    posX.value = e.clientX - mouseWidth.value / 2;
    posY.value = e.clientY - mouseWidth.value / 2;

    gsap.to(mouseElem, {
      duration: 0.4,
      x: posX.value,
      y: posY.value,
    });
  });
});
</script>

<template>
    <div class="custom-mouse"
        :class="{ active: Store.getActiveMouse}"
        :style="`height: ${mouseWidth}px; width: ${mouseWidth}px;`">
        <p>{{ Store.getCurrentLang == "GE" ? 'ვრცლად' : 'More' }}</p>
    </div>
</template>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/animations.scss";
@import "../styles/responsive.scss";

.custom-mouse {
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 50%;
    background: rgba(255, 224, 27, 0.7);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid $black;
    pointer-events: none;
    opacity: 0;
    animation: exit-fade 0.4s;

    // &.is-transition {
    //     transition: transform .1s;
    // }

    @include responsive(tablet) {
        display: none;
    }

    &.active {
        animation: enter-fade 0.4s;
        opacity: 1;
    }

    p {
        font-size: 18px;
        color: $black;
    }
}
</style>