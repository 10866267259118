<script lang="ts" setup>
import { store } from '@/store/store';

const Store = store();

</script>

<template>
    <div class="video-popup" @click="Store.openVideoPopup('')" :class="{ open: Store.getVideoPopupOpen.length }">
        <div class="frame video">
            <iframe :src="`https://www.youtube.com/embed/${Store.getVideoPopupOpen}?si=-qu1GCxAnjGaEh2T`"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/responsive.scss";
@import "../styles/mixins.scss";

.video-popup {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    animation: 0.4s close forwards;

    &.open {
        animation: 0.4s open forwards;
    }

    .frame {
        position: relative;
        width: 900px;
        height: 500px;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        @include responsive(tablet) {
            height: unset;
            width: calc(100% - 40px);
            padding-bottom: 60%;
        }
    }
}

@keyframes open {
    from {
        opacity: 0;
        display: none;
        visibility: hidden;
    }

    to {
        opacity: 1;
        display: flex;
        visibility: visible;
    }
}

@keyframes close {
    from {
        opacity: 1;
        display: flex;
        visibility: visible;
    }

    to {
        opacity: 0;
        display: none;
        visibility: hidden;
    }
}
</style>
