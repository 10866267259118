<script setup lang="ts">
import { store } from '@/store/store';
import Swiper from 'swiper';
import { device } from "@/modules/breakpoints"
import { Ref, onMounted, ref } from 'vue';
import news from '@/jsons/news.json'
import shorts from '@/jsons/shorts.json'
import { useRouter } from 'vue-router';

const Store = store();
const sliders: Ref<HTMLElement[]> = ref([]);

const router = useRouter();

let navTimeOut: number | undefined = undefined

const mySwiper = ref();
const initSwiper = (): void => {
    mySwiper.value = new Swiper('.news-slider .swiper-container', {
        // Configure Swiper options here
        slidesPerView: 2.5,
        speed: 800,
        freeMode: true,
        spaceBetween: 33,
        slidesOffsetBefore: 34,
        breakpoints: {
            320: {
                slidesOffsetBefore: 12,
                slidesPerView: 1.5,
                spaceBetween: 15,
            },
            767: {
                slidesPerView: 1.5,
                spaceBetween: 20,
            },
            1023: {
                slidesOffsetBefore: 34,
                spaceBetween: 33,
                slidesPerView: 2.5,
            }
        }
    });
    mySwiper.value.init();
    mySwiper.value.on('sliderMove', () => {
        Store.setActiveMouse(false)
        sliders.value.forEach((item: any) => {
            item.style.transform = 'scale(0.9)';
        })
    })
    mySwiper.value.on('touchEnd', () => {
        sliders.value.forEach((item: any) => {
            item.style.transform = 'scale(1)';
        })
    })
}
const navigateNews = (): void => {
    Store.setIsNavigate(true);
    window.clearTimeout(navTimeOut)
    navTimeOut = window.setTimeout(() => {
        router.push('/videos');
    }, Store.getNavLoaderTime + 200)
}

const getIframeUrl = (url: any): any => {
    return url.match(/watch\?v=([^&]+)/)[1];
}
const getshortFrameUrl = (url: any) => {
    let regex = /vi\/([^\/]*)\//;
    let match = url.match(regex);
    let id = match ? match[1] : null;
    return id;
}

const shuffleArray = (array: any) => {
    // Create a copy of the array
    let newArray = array.slice();

    // Fisher-Yates shuffle algorithm
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }

    return newArray;
}

onMounted(() => {
    initSwiper();
})
</script>

<template>
    <div class="news-slider">
        <div class="top-cont container">
            <button class="center" @click="navigateNews()">
                <p>{{ Store.getCurrentLang == "GE" ? "ვიდეოები" : "Videos" }}</p>
                <div class="icon center">
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.499961 6.99997C0.499961 7.33149 0.631658 7.64943 0.866078 7.88386C1.1005 8.11828 1.41844 8.24997 1.74996 8.24997H11.65L8.36663 11.5333C8.14583 11.7703 8.02562 12.0837 8.03134 12.4075C8.03705 12.7313 8.16824 13.0403 8.39726 13.2693C8.62628 13.4984 8.93525 13.6296 9.25909 13.6353C9.58293 13.641 9.89634 13.5208 10.1333 13.3L15.55 7.88331C15.784 7.64893 15.9155 7.33122 15.9155 6.99997C15.9155 6.66872 15.784 6.35101 15.55 6.11664L10.1333 0.699971C10.0189 0.57716 9.88086 0.478656 9.72752 0.410336C9.57419 0.342016 9.40867 0.30528 9.24083 0.302319C9.07299 0.299357 8.90628 0.330231 8.75063 0.3931C8.59498 0.455968 8.45359 0.549543 8.3349 0.66824C8.2162 0.786938 8.12262 0.928328 8.05976 1.08398C7.99689 1.23962 7.96601 1.40634 7.96898 1.57418C7.97194 1.74201 8.00867 1.90754 8.07699 2.06087C8.14531 2.2142 8.24382 2.3522 8.36663 2.46664L11.65 5.74997L1.74996 5.74997C1.05996 5.74997 0.499961 6.30997 0.499961 6.99997Z"
                            fill="black" />
                    </svg>
                </div>
            </button>
        </div>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(video, index) in news.slice(0, 10)" :key="index" ref="sliders"
                    style="transition: transform 0.4s;">
                    <div @click="Store.openVideoPopup(getIframeUrl(video.video))">
                        <div class="box" @mouseenter="Store.setActiveMouse(true)"
                            @mousemove="Store.setActiveMouse(true)" @mouseleave="Store.setActiveMouse(false)">
                            <div class="img">
                                <img draggable="false"
                                    v-lazy="{ src: Store.getWindowWidth <= device.tablet ? video.thumbMobile : video.thumbDesktop, loading: require('@/assets/loading.gif') }"
                                    class="abs-img" alt="News Image" />
                            </div>
                            <p class="title">{{ video.title }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="top-cont short-header container">
            <button class="center">
                <p>{{ Store.getCurrentLang == "GE" ? "მოკლე ვიდეოები" : "Shorts" }}</p>
            </button>
        </div>
        <div class="shorts container">
            <div class="mask"></div>
            <div class="row" v-for="(row, index) in 4" :key="index">
                <div class="inner-row" :class="{ 'bottom': index === 1 || index === 3 }">
                    <div class="short" v-for="(short, index) in shuffleArray(shorts)" :key="index"
                        @click="Store.openVideoPopup(getshortFrameUrl(short.thumbnail))" @mouseenter="Store.setActiveMouse(true)"
                        @mousemove="Store.setActiveMouse(true)" @mouseleave="Store.setActiveMouse(false)">
                        <img v-lazy="{ src: short.thumbnail, loading: require('@/assets/loading.gif') }" class="abs-img"
                            alt="Short image" />
                    </div>
                </div>
            </div>
            <div class="mask last"></div>

        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "../styles/mixins.scss";
@import "../styles/variables.scss";
@import "../styles/responsive.scss";

$animationSpeed: 80s;

@keyframes move-top {
    from {
        transform: translateY(0%);
    }

    to {
        transform: translateY(-100%);

    }
}

@keyframes move-bottom {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0%);

    }
}

.news-slider {
    padding-top: 67px;
    overflow: hidden;
    position: relative;
    .swiper-container {
        margin-bottom: 50px;
    }

    .shorts {
        height: 600px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        position: relative;
        margin-top: 20px;

        @include responsive(mobile-large) {
            justify-content: flex-start;
        }

        .mask {
            &.last {
                bottom: 0px;
                top: unset;
                transform: rotate(180deg);
            }

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: transparent linear-gradient(180deg, rgb(255 255 255) 0%, rgba(37, 41, 43, 0) 60%) 0% 0% no-repeat padding-box;
            height: 40px;
            z-index: 2;
        }

        .row {
            width: 25%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            z-index: 1;

            @include responsive(desktop-small) {
                width: 33.33%;

                &:nth-child(4) {
                    display: none;
                }
            }

            @include responsive(tablet) {
                width: 50%;

                &:nth-child(3) {
                    display: none;
                }
            }

            .inner-row {
                position: absolute;
                top: 0;

                &.bottom {
                    animation: $animationSpeed move-bottom linear infinite;
                }

                animation: $animationSpeed move-top linear infinite;
            }

            .short {
                position: relative;
                width: 270px;
                height: 440px;
                overflow: hidden;
                border-radius: 10px;
                margin-bottom: 40px;

                @include responsive(tablet) {
                    width: 230px;
                    height: 380px;

                }

                @include responsive(mobile-large) {
                    width: 140px;
                    height: 230px;
                }
            }
        }
    }

    @include responsive(mobile-large) {
        padding-top: 30px;
    }

    .top-cont {
        margin-bottom: 15px;

        &.short-header {
            button {
                text-decoration: none !important;
                cursor: auto;
            }
        }

        button {
            background: transparent;
            cursor: pointer;
            transition: 0.4s;

            @include hover() {
                text-decoration: underline;
            }

            .icon {
                margin-top: -5px;
            }

            p {
                font-size: 24px;
                padding-right: 8px;
            }
        }
    }

    .box {
        .img {
            position: relative;
            padding-bottom: 55%;
            border: 7px solid $black;

            @include responsive(mobile-large) {
                border: 5px solid $black;
            }
        }

        .title {
            font-size: 32px;
            margin-top: 25px;
            @include splitText(1);

            @include responsive(desktop-small) {
                font-size: 24px;
            }

            @include responsive(mobile-large) {
                font-size: 14px;
                margin-top: 10px;
            }
        }
    }
}
</style>